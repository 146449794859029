.container {
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.content {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 90px;
}

.left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.footer {
    background-color: #1a1825;
    width: 100%;
    min-height: 200px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.item-title {
    width: 300px;
}

.item-footer {
    width: 200px;
}


@media only screen and (max-width: 600px) {

    .right,
    .left {
        width: 95%;
        min-width: 0px;
    }

    .item-title {
        margin-top: 20px;
    }

    .item-footer {
        margin-top: 20px;
        width: 300px;
    }

}