.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 15px 0;
    justify-content: center;
    position: relative;
}

.left {
    width: 50%;
    display: flex;
    justify-content: center;
    min-width: 350px;
    padding: 15px 0;
    position: relative;
}

.right {
    width: 50%;
    position: relative;
}


.title-hidden {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
}

.title-overview {
    height: 145px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    display: -webkit-box;
    overflow: hidden;
}

.custom-diamond {
    position: absolute;
    right: 130px;
    bottom: 170px;
    z-index: -1;
    width: 380px;
    height: 380px;
}

@media only screen and (max-width: 700px) {

    .left,
    .right {
        width: 100%;
    }

    .custom-diamond {
        width: 210px;
        height: 210px;
    }

}