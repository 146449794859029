.container {
    padding: 100px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
}

.left {
    width: 60%;
}

.right {
    width: 40%;
    max-height: 600px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    max-width: 550px;
    border-radius: 5px;
    position: fixed;
    left: 60%;
    padding: 10px;
}

.title-hidden {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
}

@media only screen and (max-width: 700px) {

    .left {
        width: 95%;
        margin: auto;
    }
    .right{
        width: 96%;
        margin-left: 2%;
        margin-top: 15px;
        position: relative;
        left: 0%;
    }

}