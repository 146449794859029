.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 150px;
}

.left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 250px;
    padding: 0 15px;
}

.container-establish {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 650px;
}

.description {
    width: 100%;
    border-left: 2px solid #d8d8d8;
    color: #7f7f7f;
    font-size: 20px;
    padding-left: 20px;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    .header {
        display: none;
    }

    .left,
    .right {
        width: 100%;
        margin: auto;
    }
}