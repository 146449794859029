.container {
    display: flex;
    flex-wrap: wrap;
}

.left {
    width: 400px;
    display: flex;
    align-items: center;
    margin: auto;
    padding: 0 20px;

}

.right {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 400px);
    justify-content: space-around;
    max-width: 1095px;
    margin: auto;
    position: relative;
}

.diamond {
    transform: rotate(45deg);
    margin: 50px;
    border-radius: 30px;
    animation: moveUpDown 2s ease-in-out infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 22px solid #b2ebf2;
    border-right: 22px solid #b2ebf2;
    width: 170px;
    height: 170px;
    background-color: #fff;
}


.container-diamond {
    animation: floatUpDown 2s ease infinite;
}

.title-hidden-card {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 0;
    max-height: 64px;
    width: 90%;
    margin: auto;
}

.title-hidden-content {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    font-size: 14px;
    // max-height: 100px;
    width: 90%;
    margin: auto;
}


@media only screen and (max-width: 1100px) {

    .right {
        width: 100%;
    }
}


@keyframes floatUpDown {

    0%,
    100% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-10px);
    }
}