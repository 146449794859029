.container {
    padding-top: 100px;
    display: flex;
    flex-wrap: wrap;
}

.left {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
}

.right {
    width: 50%;
    max-width: 550px;
    margin: auto;
    z-index: 1;

}

@media only screen and (max-width: 800px) {

    .right {
        width: 95%;

    }

    .left {
        width: 100%;
    margin: auto;
    }
}