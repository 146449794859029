#home {
    padding-top: 60px;
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.home-left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 300px;
    z-index: 1;
}

.home-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#about,
#ourPartner,
#exploreNews {
    padding-top: 60px;

}

#service {
    padding: 60px 0;
    background: linear-gradient(91.29deg, #91d5ff 1.1%, #f8f9fb 110.64%);
}

#our-awesome-team{
    background: rgb(249 246 246);
}

#contact{
    width: 100% ;
}

img {
    max-width: 95%;
    max-height: 95%;

}

.container-diamond {
    animation: floatUpDown 2s ease infinite;
}

.custom-diamond {
    position: absolute;
    left: 4%;
    top: 460px;
    z-index: -1;
    width: 350px;
    height: 350px;
}

.diamond {
    transform: rotate(45deg);
    margin: 50px;
    border-radius: 30px;
    animation: moveUpDown 2s ease-in-out infinite;
    display: flex;
    align-items: center;
    justify-content: center;
}

.diamond-title {
    transform: rotate(-45deg);
    font-size: 30px;
    font-weight: bold;
}

.animate-border span {
    position: absolute;
    display: block;
    width: 5%;
    padding-bottom: 5%;
    top: 38%;
    left: 42%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none;
    border-radius: 50%;
    z-index: 2;
}


.animate-border span:nth-child(2) {
    -webkit-animation: pulsei 4.2s ease-out 1.5s infinite normal none running;
    animation: pulsei 4.2s ease-out 1.5s infinite normal none running
}

.animate-border span:nth-child(3) {
    -webkit-animation: pulsei 4.2s ease-out 2.5s infinite normal none running;
    animation: pulsei 4.2s ease-out 2.5s infinite normal none running
}

.animate-border span:nth-child(4) {
    -webkit-animation: pulsei 4.2s ease-out 3.5s infinite normal none running;
    animation: pulsei 4.2s ease-out 3.5s infinite normal none running
}



.animate-border span:first-child {
    -webkit-animation: pulsei 4.2s ease-out .5s infinite normal none running;
    animation: pulsei 4.2s ease-out .5s infinite normal none running;
}

@media only screen and (max-width: 600px) {

    .home-left,
    .home-right {
        width: 95%;
        margin: auto;
    }

    .custom-diamond {
        width: 0px;
        height: 0px;
    }


}


@keyframes floatUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}


@keyframes pulsei {
    0% {
        // -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        // transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        border: 1.5px solid #b3eaf8;
        opacity: 1;
        width: 5%;
        padding-bottom: 5%
    }

    to {
        // -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        // transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 0;
        width: 70%;
        border: 1.5px solid #b3eaf8;
        padding-bottom: 70%
    }
}


