.header {
    position: fixed;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: #fff;
}

.header-mobile{
    display: none;
}

@media only screen and (max-width: 700px) {

    .header{
        display: none;
    }

    .header-mobile{
        display: block;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        left: 50%;
        top: 5px;
        transform: translate(-50%);
    }
 
}