.banner-bg {
    width: 100%;
    height: 400px;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}


.title-img {
    font-size: 32px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.description-img {
    max-width: 900px;
    width: 95%;
    font-style: italic;
    height: 250px;
    font-size: 18px;
}

.container-product {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.container-description {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    width: 100%;
    padding: 45px 0;
}

.description-left {
    width: 50%;
    max-width: 450px;
    min-width: 360px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.description-right {
    width: 50%;
    max-width: 450px;
    min-width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.oem-block {
    width: 90%;
    max-width: 1300px;
    height: 432px;
    margin: auto;
    position: relative;
}

.banner-footer-desktop {
    float: right;
}

.banner-footer-mobile {
    display: none;
}

.content-footer {
    max-width: 500px;
    position: absolute;
    right: 0;
    height: 432px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    color: #fff;
}

@media screen and (max-width: 640px) {
    .banner-footer-desktop {
        display: none;
    }

    .banner-footer-mobile {
        display: block;
    }
}